<div *ngIf="group" [formGroup]="group">
  <h4>Education</h4>
  <div formGroupName="education" class="margin-left-lg" fxLayout>
    <div fxLayout="row" fxLayout.lt-md="column">
      <mat-form-field class="custom-right-margin">
        <input matInput [readonly]="true" placeholder="Highest grade" formControlName="highestGradeCompleted">
      </mat-form-field>
    <div >
      <mat-form-field>
        <input matInput [readonly]="true" placeholder="College" formControlName="lastSchoolAttended">
      </mat-form-field>
    </div>
    </div>
  </div>
</div>