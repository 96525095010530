import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperProdSupportComponent } from './shipper-prod-support/shipper-prod-support.component';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { SharedUiOverlayModule } from '@fleetoperate/shared/ui/overlay';
import { ShipmentListModule } from '../shipment-list/shipment-list.module';
import { ErrorsModule } from '@fleetoperate/delivery-recruiter/feature-errors';
import { MatRadioModule, MatButtonModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [ShipperProdSupportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatRadioModule,
    ShipmentListModule,
    ErrorsModule,
    SharedUiFleetoperateToolbarModule,
    SharedUiHeaderModule,
    SharedUiOverlayModule
  ]
})
export class ShipperProdSupportModule {}
