import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DriverDataModule } from '@fleetoperate/shared/driver/data-access-driver';
import { TruckTypeComponent } from './truck-type/truck-type.component';
import { DocumentsDataModule } from '@fleetoperate/shared/documents/data-access-documents';
import { DriverProfileComponent } from './driver-profile/driver-profile.component';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DrivingExperienceComponent } from './driving-experience/driving-experience.component';
import { EmploymentHistoryComponent } from './employment-history/employment-history.component';
import { DriverLicenseDetailsComponent } from './driver-license-details/driver-license-details.component';
import { DriverPersonalInformationComponent } from './driver-personal-information/driver-personal-information.component';
import { DriverEducationComponent } from './driver-education/driver-education.component';
import { MatTableModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { DriverDocumentListComponent } from './driver-documents-list/driver-document-list.component';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { DocumentUploadDialogComponent } from './document-upload-dialog/document-upload-dialog.component';
import { DateTimeService, DateTimeModule, ToastService, ToastModule } from '@fleetoperate/shared/util';

@NgModule({
  declarations: [
    DriverProfileComponent,
    TruckTypeComponent,
    DrivingExperienceComponent,
    EmploymentHistoryComponent,
    DriverLicenseDetailsComponent,
    DriverPersonalInformationComponent,
    DriverEducationComponent,
    DriverDocumentListComponent,
    DocumentUploadDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    SharedUiFleetoperateToolbarModule,
    SharedUiHeaderModule,
    DriverDataModule,
    FlexLayoutModule,
    ToastModule,
    DocumentsDataModule,
    DateTimeModule,
    MatTableModule
  ],
  entryComponents: [DocumentUploadDialogComponent],
  providers: [DateTimeService]
})
export class DriverProfileModule {}
