<div *ngIf="group" [formGroup]="group">
  <mat-card class="margin-bottom-md">
    <div fxLayout="row" fxLayout.lt-md="column">
      <mat-form-field class="long-form-field margin-left-lg">
        <input matInput [readonly]="true" placeholder="Phone" formControlName="phoneNumber">
      </mat-form-field>

      <mat-form-field class="long-form-field long-form-field margin-left-lg">
        <input matInput [readonly]="true" placeholder="Email" formControlName="email">
      </mat-form-field>

      <mat-form-field class="long-form-field margin-left-lg">
        <input matInput [readonly]="true" placeholder="Profile Status" formControlName="identityStatus">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column">
      <mat-form-field class="margin-left-lg address">
        <input matInput [readonly]="true" placeholder="Address" formControlName="address">
      </mat-form-field>
    </div>
  </mat-card>
</div>