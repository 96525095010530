<fleetoperate-fleetoperate-toolbar [data]="user" (logout)="onLogout()"></fleetoperate-fleetoperate-toolbar>

<fleetoperate-header
  [title]="title"
  [backAction]="backAction"
  [actions]="viewType == 'documents-view' ? actions : undefined"
  class="margin-left-md margin-right-md"
>
</fleetoperate-header>

<div fxLayout fxLayout.lt-sm="column" class="margin-bottom-md default-background">
  <div fxLayout="column" fxLayoutGap="16px" class="padding-right-sm">
    <div fxLayout="column" fxLayoutGap="16px" class="side margin-left-sm">
      <span class="full-name">{{ fullName }}</span>
      <mat-icon class="icon">person</mat-icon>

      <button mat-raised-button (click)="onProfileClick()" [disabled]="loading">Profile</button>
      <button mat-raised-button (click)="onDocumentsClick()" [disabled]="loading">Documents</button>
    </div>
  </div>
  <div fxFlex fxLayout="column" class=" padding-sm">
    <span *ngIf="errorMessage">{{ errorMessage }}</span>
    <span *ngIf="successMessage">{{ successMessage }}</span>
    <form fxLayout="column" [formGroup]="form">
      <div class="profile-info" fxFlex fxLayout="column">
        <fleetoperate-driver-personal-information [group]="form"></fleetoperate-driver-personal-information>
      </div>
      <ng-container [ngSwitch]="viewType">
        <ng-container *ngSwitchCase="'default-view'">
          <mat-card>
            <fleetoperate-driver-license-details [group]="form"></fleetoperate-driver-license-details>
            <hr />

            <fleetoperate-employment-history [group]="form"></fleetoperate-employment-history>

            <hr />

            <div fxLayout="column">
              <fleetoperate-driving-experience *ngIf="form" [group]="form.get('experience')">
              </fleetoperate-driving-experience>

              <div fxLayout="row" fxLayout.lt-md="column">
                <div fxLayout="column margin-right-lg">
                  <fleetoperate-truck-type
                    *ngIf="
                      form &&
                      form.get('experience').get('straightTruck').value.milesDriven &&
                      form.get('experience').get('straightTruck').value.fromDate &&
                      form.get('experience').get('straightTruck').value.toDate
                    "
                    [group]="form.get('experience').get('straightTruck')"
                  >
                  </fleetoperate-truck-type>
                  <fleetoperate-truck-type
                    *ngIf="
                      form &&
                      form.get('experience').get('tractorTwo').value.milesDriven &&
                      form.get('experience').get('tractorTwo').value.fromDate &&
                      form.get('experience').get('tractorTwo').value.toDate
                    "
                    [group]="form.get('experience').get('tractorTwo')"
                  >
                  </fleetoperate-truck-type>
                </div>
                <div fxLayout="column margin-left-lg">
                  <fleetoperate-truck-type
                    *ngIf="
                      form &&
                      form.get('experience').get('tractor').value.milesDriven &&
                      form.get('experience').get('tractor').value.fromDate &&
                      form.get('experience').get('tractor').value.toDate
                    "
                    [group]="form.get('experience').get('tractor')"
                  >
                  </fleetoperate-truck-type>
                  <fleetoperate-truck-type
                    *ngIf="
                      form &&
                      form.get('experience').get('others').value.milesDriven &&
                      form.get('experience').get('others').value.fromDate &&
                      form.get('experience').get('others').value.toDate
                    "
                    [group]="form.get('experience').get('others')"
                  >
                  </fleetoperate-truck-type>
                </div>
              </div>
            </div>

            <hr />

            <fleetoperate-driver-education [group]="form"></fleetoperate-driver-education>
          </mat-card>
        </ng-container>

        <ng-container *ngSwitchCase="'documents-view'">
          <fleetoperate-driver-document-list
            #driverDocumentListComponent
            [loading]="loading"
            [dataSource]="driverNotifications"
            [driverDocuments]="driverDocuments"
            (downloadDocument)="onDriverDocumentDownload($event)"
          >
          </fleetoperate-driver-document-list>
        </ng-container>
      </ng-container>
      <!-- </div> -->
      <!-- <div class="profile-info">
        <mat-checkbox formControlName="driverScore" labelPosition="after">Professional Driver Improvement score</mat-checkbox>
        <mat-checkbox formControlName="noTouchFreight" labelPosition="after">No-touch freight</mat-checkbox>
        <mat-checkbox formControlName="drugTest" labelPosition="after">Pre hire drug test</mat-checkbox>
        <mat-checkbox formControlName="isHazmat" labelPosition="after">is Hazmat?</mat-checkbox>
      </div> -->
    </form>
  </div>
</div>
