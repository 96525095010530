import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@fleetoperate/shared/authentication/feature-authentication';
import { ROUTE_SHIPPER_SEARCH, ROUTE_SHIPPER_PROD_SUPPORT, ROUTE_SHIPMENT_SEARCH } from './shared/routes';
import { RecruiterGuardService } from '@fleetoperate/delivery-recruiter/feature-shell';
import { ShipperSearchComponent } from './shipper-search/shipper-search/shipper-search.component';
import { ShipperProdSupportComponent } from './shipper-prod-support/shipper-prod-support/shipper-prod-support.component';

const routes: Routes = [
  {
    path: ROUTE_SHIPPER_SEARCH,
    component: ShipperSearchComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  },
  {
    path: ROUTE_SHIPPER_PROD_SUPPORT,
    component: ShipperProdSupportComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  },
  {
    path: ROUTE_SHIPMENT_SEARCH,
    component: ShipperProdSupportComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecruiterFeatureShipperRoutingModule {}
