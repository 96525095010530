<fleetoperate-fleetoperate-toolbar [data]="user" (logout)="onLogout()"></fleetoperate-fleetoperate-toolbar>
<fleetoperate-header
  [title]="title"
  [actions]="actions"
  [backAction]="backAction"
  class="margin-left-lg margin-right-md"
></fleetoperate-header>
<div fxLayout>
  <mat-radio-group class="view-options" fxLayout="column" [formControl]="viewCtrl">
    <mat-radio-button
      *ngFor="let view of views"
      disableRipple="true"
      class="button primary-button"
      [value]="view.value"
    >
      <p class="full-width center-content">{{ view.text }}</p>
    </mat-radio-button>
  </mat-radio-group>
  <recruiter-shipment-list
    fxFlex
    *ngIf="showShipments"
    [logoutEvent]="logoutSubject.asObservable()"
  ></recruiter-shipment-list>
  <fleetoperate-errors fxFlex *ngIf="showErrors" [logoutEvent]="logoutSubject.asObservable()"></fleetoperate-errors>
</div>
