import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'fleetoperate-truck-type',
  templateUrl: './truck-type.component.html',
  styleUrls: ['./truck-type.component.scss']
})
export class TruckTypeComponent implements OnInit {

  @Input() group: FormGroup;
  invalidDate = "Invalid date";
  constructor() { }

  ngOnInit() { }

}
