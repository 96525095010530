import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { SharedRecruiterDataAccessRecruiterModule } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { RecruiterFeatureDriverModule } from '@fleetoperate/delivery-recruiter/feature-driver';
import { ShellComponent } from './shell.component';
import { RecruiterFeatureShipperModule } from '@fleetoperate/delivery-recruiter/feature-shipper';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    RouterModule,
    UiKitMaterialModule,
    SharedRecruiterDataAccessRecruiterModule,
    RecruiterFeatureDriverModule,
    RecruiterFeatureShipperModule
  ],
  providers: [],
  bootstrap: [ShellComponent]
})
export class ShellModule {}
