<div fxLayout="column">
  <form class="shipment-summary-filters" fxLayout>
    <mat-form-field fxFlexAlign="center">
      <input matInput placeholder="Search" [formControl]="idCntrl" (input)="onSearchInput()" #idInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <button mat-raised-button type="button" class="button" fxFlexAlign="center" (click)="clearFilters()">CLEAR</button>
    <mat-radio-group class="full-width status-options" fxLayout="row-reverse" [formControl]="statusCtrl">
      <mat-radio-button
        disableRipple="true"
        class="button secondary-button"
        *ngFor="let status of this.allStatus"
        fx-layout-align="end"
        [value]="status"
      >
        <p class="full-width center-content">{{ status }}</p>
      </mat-radio-button>
    </mat-radio-group>
  </form>

  <div class="margin-bottom-md margin-right-sm margin-bottom-md margin-left-sm" fxLayout="column">
    <table mat-table [dataSource]="filteredShipments">
      <ng-container matColumnDef="shipmentId">
        <th mat-header-cell *matHeaderCellDef>SHIPMENT ID</th>
        <td mat-cell *matCellDef="let element">{{ element.shipmentID }}</td>
      </ng-container>

      <ng-container matColumnDef="mode">
        <th mat-header-cell *matHeaderCellDef>MODE</th>
        <td mat-cell *matCellDef="let element">{{ element.fitsIn }}</td>
      </ng-container>

      <ng-container matColumnDef="origin">
        <th mat-header-cell *matHeaderCellDef>ORIGIN</th>
        <td mat-cell *matCellDef="let element">{{ element.origin }}</td>
      </ng-container>

      <ng-container matColumnDef="destination">
        <th mat-header-cell *matHeaderCellDef>DESTINATION</th>
        <td mat-cell *matCellDef="let element">{{ element.destination }}</td>
      </ng-container>

      <ng-container matColumnDef="pickup">
        <th mat-header-cell *matHeaderCellDef>ESTIMATED PICKUP</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedPickupDateAndTime }}</td>
      </ng-container>

      <ng-container matColumnDef="delivery">
        <th mat-header-cell *matHeaderCellDef>ESTIMATED DELIVERY</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedDeliveryDateAndTime }}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>PRICE</th>
        <td mat-cell *matCellDef="let element">{{ element.formattedOfferRate }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div fxFlexAlign="center" class="table-message">
    <mat-spinner class="margin-top-md padding-bottom-md" *ngIf="loading"></mat-spinner>
    <p *ngIf="(!length || length <= 0) && !loading">No shipments found.</p>
  </div>
  <!-- <mat-paginator [length]="length" [pageSize]="pageSizeOptions[0]" [pageSizeOptions]="pageSizeOptions"
    (page)="handlePage($event)">
  </mat-paginator> -->
</div>
