import { TruckType } from './truck-type.model';

export class DrivingExperience {
    id: string;
    drivingGraduate: boolean;
    currentStatus: string;
    companyDriver: boolean;
    studentDriver: boolean;
    ownerOperator: boolean;
    yearsOfExperience: number;
    truckTypes: TruckType[];
}
