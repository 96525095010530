import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperSearchComponent } from './shipper-search/shipper-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ShipperDataModule } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [ShipperSearchComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatProgressSpinnerModule,
    SharedUiFleetoperateToolbarModule,
    SharedUiHeaderModule,
    ShipperDataModule
  ]
})
export class ShipperSearchModule {}
