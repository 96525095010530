export class TruckTypePreference {
  any: boolean;
  hhg: boolean;
  reefer: boolean;
  hazmat: boolean;
  tanker: boolean;
  carHauling: boolean;
  flatBed: boolean;
  van: boolean;
  specialized: boolean;
  lcv: boolean;
}
