import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverSearchComponent } from './driver-search/driver-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DriverDataModule } from '@fleetoperate/shared/driver/data-access-driver';
import { MatIconModule } from '@angular/material/icon';
import { SharedUiFleetoperateToolbarModule } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { SharedUiHeaderModule } from '@fleetoperate/shared/ui/header';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [DriverSearchComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatTableModule,
    MatProgressSpinnerModule,
    SharedUiFleetoperateToolbarModule,
    SharedUiHeaderModule,
    DriverDataModule
  ]
})
export class DriverSearchModule { }
