<div class="mat-app-background upload-dialog padding-top-md">
  <form [formGroup]="driverDocumentForm" novalidate fxLayout="column" fxLayoutGap="16px">
    <mat-form-field class="margin-right-md margin-left-md">
      <mat-label>Report type</mat-label>
      <mat-select formControlName="documentType" required>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let driverDocumentType of data.driverDocumentTypes" [value]="driverDocumentType.name">
          {{ driverDocumentType.displayName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="documentType.touched && documentType.invalid">
        {{ getDocumentTypeErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md margin-left-md">
      <mat-hint align="start">Freeform text input. Max of 50 characters</mat-hint>
      <input matInput placeholder="Report Source" formControlName="source" required />
      <mat-error *ngIf="source.touched && source.invalid">
        {{ getSourceErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md margin-left-md">
      <input
        matInput
        readonly
        [matDatepicker]="reportGenerationDate"
        [max]="maxDate"
        placeholder="Report Generation Date"
        formControlName="reportGenerationDate"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="reportGenerationDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #reportGenerationDate></mat-datepicker>
      <mat-error *ngIf="reportGenerationDate.touched && reportGenerationDate.invalid">
        {{ getReportGenerationDateErrorMessage() }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="margin-right-md margin-left-md">
      <input
        matInput
        readonly
        [matDatepicker]="validToDate"
        placeholder="Report Valid To Date"
        formControlName="validToDate"
      />
      <mat-datepicker-toggle matSuffix [for]="validToDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi="true" #validToDate></mat-datepicker>
    </mat-form-field>

    <input
      mat-input
      type="file"
      accept="application/pdf,image/jpeg"
      class="margin-left-md"
      (change)="onFileChange($event)"
    />
    <div mat-dialog-actions>
      <button mat-button (click)="dialogRef.close(false)" class="button" cdkFocusInitial>Cancel</button>
      <button
        mat-raised-button
        [disabled]="!driverDocumentForm.dirty || !driverDocumentForm.valid"
        [mat-dialog-close]="driverDocumentForm"
      >
        Upload
      </button>
    </div>
  </form>
</div>
