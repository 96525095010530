import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@fleetoperate/shared/authentication/feature-authentication';
import { ROUTE_DRIVER_SEARCH, ROUTE_DRIVER_PROFILE, ROUTE_DRIVER_PROD_SUPPORT } from './shared/routes';
import { RecruiterGuardService } from '@fleetoperate/delivery-recruiter/feature-shell';
import { DriverSearchComponent } from './driver-search/driver-search/driver-search.component';
import { DriverProfileComponent } from './driver-profile/driver-profile/driver-profile.component';
import { DriverProdSupportComponent } from './driver-prod-support/driver-prod-support/driver-prod-support.component';

const routes: Routes = [
  {
    path: ROUTE_DRIVER_SEARCH,
    component: DriverSearchComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  },
  {
    path: `${ROUTE_DRIVER_PROFILE}/:id`,
    component: DriverProfileComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  },
  {
    path: ROUTE_DRIVER_PROD_SUPPORT,
    component: DriverProdSupportComponent,
    canActivate: [AuthGuardService, RecruiterGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecruiterFeatureDriverRoutingModule {}
