import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ErrorsModule } from './errors.module';
import { Error, ErrorDataService } from '@fleetoperate/shared/data-access-error';

@Injectable({
  providedIn: ErrorsModule
})
export class ErrorsService {
  constructor(private readonly errorDataService: ErrorDataService) {}

  getErrors(capturedTime: string): Observable<Error[]> {
    return this.errorDataService.loadErrors(capturedTime);
  }
}
