import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Driver,
  Employment,
  Education,
  DrivingExperience,
  TruckType,
  Notification
} from '@fleetoperate/shared/driver/data-access-driver';
import { DriverProfileService } from '../driver-profile.service';
import { SubSink } from 'subsink';
import { ROUTE_DRIVER_SEARCH } from '../../shared/routes';
import {
  DocumentsDataService,
  DocumentType,
  DriverDocument
} from '@fleetoperate/shared/documents/data-access-documents';
import { tap, catchError, first, map, switchMap } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { SendDocumentResponse } from '@fleetoperate/shared/documents/data-access-documents';
import * as mime from 'mime';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import * as moment from 'moment-timezone';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { DocumentUploadDialogComponent } from '../document-upload-dialog/document-upload-dialog.component';
import { MatDialog } from '@angular/material';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const DRIVER_DOCUMENT_STATUS_UPLOADED = 'uploaded';
const DRIVER_DOCUMENT_STATUS_PENDING = 'pending';
const VIEW_TYPE_DEFAULT = 'default-view';
const VIEW_TYPE_DOCUMENT = 'documents-view';

@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.scss']
})
export class DriverProfileComponent implements OnInit, OnDestroy {
  form: FormGroup;
  duration: string;

  selectedFiles: FileList;
  selectedReport: any;
  driverDocumentTypes: DocumentType[];
  driverDocumentForm: FormGroup;

  @ViewChild('driverDocumentListComponent', { static: false }) driverDocumentListComponent;
  driverNotifications: Notification[];
  driverDocuments: DriverDocument[];

  errorMessage: string;
  successMessage: string;
  loading: boolean;
  fullName: string;
  user: User;
  viewType: string;
  backAction: Function;
  title: string;
  actions: Array<TabActionLink> = [];

  private subs = new SubSink();
  private driverID: string;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    private readonly recruiterDataService: RecruiterDataService,
    private readonly driverProfileService: DriverProfileService,
    private readonly documentsDataService: DocumentsDataService,
    private activatedRoute: ActivatedRoute
  ) {
    this.updateLoadingState(false);
    this.backAction = () => this.router.navigate([ROUTE_DRIVER_SEARCH]);
    this.errorMessage = undefined;
    this.successMessage = undefined;
    this.form = this.createForm();
    this.title = 'Driver Profile';
    this.driverDocumentForm = this.createDriverDocumentForm();
    this.viewType = VIEW_TYPE_DEFAULT;
  }

  ngOnInit() {
    this.user = {
      name: this.recruiterDataService.getRecruiter() && this.recruiterDataService.getRecruiter().email
    };
    this.driverID = this.activatedRoute.snapshot.paramMap.get('id');
    this.actions.push({
      title: 'UPLOAD',
      disabled: this.uploadDisabled(),
      clickHandler: () => this.onDocumentUpload()
    } as TabActionLink);
    this.fetchData();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNavigateToFindDriver(): void {
    this.router.navigate([ROUTE_DRIVER_SEARCH]);
  }

  private uploadDisabled(): boolean {
    return this.loading;
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.driverDocumentForm.patchValue({
          file: file,
          filename: file.name
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onDocumentUpload(): void {
    const dialogRef = this.dialog.open(DocumentUploadDialogComponent, {
      data: { driverDocumentTypes: this.driverDocumentTypes, driverID: this.driverID },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(documentForm => {
      if (!documentForm || !documentForm.value) {
        return;
      }
      this.updateLoadingState(true);
      this.updateActionUpLoad();
      this.errorMessage = undefined;
      this.successMessage = undefined;

      const reportType = documentForm.value.documentType;
      const fileName = documentForm.value.filename;
      const extension = fileName.split('.')[1].toLowerCase();
      const source = documentForm.value.source;
      const validToDate = documentForm.value.validToDate
        ? moment(documentForm.value.validToDate).format('YYYY-MM-DDTHH:mm:ss')
        : undefined;
      const generationDate = moment(documentForm.value.reportGenerationDate).format('YYYY-MM-DDTHH:mm:ss');

      const validToDateUTC = validToDate
        ? moment(validToDate)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ssZ')
        : undefined;
      const generationDateUTC = moment(generationDate)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ssZ');

      this.documentsDataService
        .sendDriverDocument(this.driverID, reportType, extension, source, generationDateUTC, validToDateUTC)
        .pipe(
          first(),
          switchMap((sendDocumentResponse: SendDocumentResponse) => {
            const url = sendDocumentResponse.url;
            const newFileName = sendDocumentResponse.file;

            const file = documentForm.value.file;
            const renamedFile: File = new File([file], newFileName, { type: file.type });
            const contentType = mime.getType(extension);

            return this.documentsDataService.uploadDriverDocument(renamedFile, url, contentType);
          }),
          map(() => {
            this.successMessage = `File was uploaded.`;
            this.errorMessage = undefined;
            this.updateLoadingState(false);
            this.updateActionUpLoad();
          }),
          tap(() => this.fetchData()),
          catchError((error: string) => {
            this.successMessage = undefined;
            this.errorMessage = ERROR_MESSAGE;
            this.updateLoadingState(false);
            this.updateActionUpLoad();
            throw error;
          })
        )
        .subscribe();
    });
  }

  get driverDocumentFormDocumentType(): FormGroup {
    return this.driverDocumentForm.controls.documentType as FormGroup;
  }
  get driverDocumentFormFile(): FormControl {
    return this.driverDocumentForm.controls.file as FormControl;
  }
  get driverDocumentFormFilename(): FormControl {
    return this.driverDocumentForm.controls.filename as FormControl;
  }

  createDriverDocumentForm(): FormGroup {
    return this.fb.group({
      driverConsentReceived: [false, Validators.required],
      documentType: [undefined],
      file: [null, Validators.required],
      filename: [undefined]
    });
  }

  determineDriverDocumentIcon(driverDocumentStatus: string): string {
    if (DRIVER_DOCUMENT_STATUS_UPLOADED === driverDocumentStatus) {
      return 'done';
    } else if (DRIVER_DOCUMENT_STATUS_PENDING === driverDocumentStatus) {
      return 'autorenew';
    } else {
      return 'block';
    }
  }

  determineDriverDocumentIconColor(driverDocumentStatus: string): string {
    if (DRIVER_DOCUMENT_STATUS_UPLOADED === driverDocumentStatus) {
      return 'success';
    } else if (DRIVER_DOCUMENT_STATUS_PENDING === driverDocumentStatus) {
      return 'secondary-color';
    } else {
      return 'secondary-color';
    }
  }

  private fetchData(): void {
    this.subs.add(
      combineLatest(this.loadDriver(this.driverID), this.loadDriverDocumentTypes())
        .pipe(first())
        .subscribe(([driver, documentTypes]) => {
          this.driverNotifications = driver.notification;
          this.driverDocuments = this.determineDriverDocument(documentTypes, driver.notification);
        })
    );
  }

  private loadDriver(id: string): Observable<Driver> {
    return this.driverProfileService.getDriverById(id).pipe(
      tap((driver: Driver) => {
        this.errorMessage = undefined;
        this.updateLoadingState(false);
        this.fullName = driver.firstName.toUpperCase() + ' ' + driver.lastName.toUpperCase();
        this.populateForm(driver);
      }),
      catchError((error: string) => {
        this.errorMessage = ERROR_MESSAGE;
        this.updateLoadingState(false);
        throw error;
      })
    );
  }

  onProfileClick() {
    this.viewType = VIEW_TYPE_DEFAULT;
  }

  onDocumentsClick() {
    this.viewType = VIEW_TYPE_DOCUMENT;
  }

  private determineDriverDocument(documentTypes: DocumentType[], notifications: Notification[]): DriverDocument[] {
    const driverDocuments: DriverDocument[] = [];

    documentTypes.forEach((documentType: DocumentType) => {
      if (!documentType) {
        return;
      }

      const notification = notifications.find((notification: Notification) => {
        if (!notification) {
          return;
        }
        return notification.type === documentType.name;
      });

      const driverDocument = {
        id: notification ? notification.id : undefined,
        status: notification ? notification.status : undefined,
        type: notification ? notification.type : undefined,
        name: documentType.displayName,
        verifiedDate: notification ? notification.verifiedDate : undefined
      } as DriverDocument;

      driverDocument.icon = this.determineDriverDocumentIcon(driverDocument.status);
      driverDocument.iconColor = this.determineDriverDocumentIconColor(driverDocument.status);

      driverDocuments.push(driverDocument);
    });

    return driverDocuments;
  }

  onDriverDocumentDownload(shipmentDocumentId: string): void {
    this.successMessage = undefined;
    this.errorMessage = undefined;
    this.updateLoadingState(true);
    this.actions = this.createActions();
    this.driverProfileService
      .downloadDriverDocument(this.driverID, shipmentDocumentId)
      .pipe(first())
      .subscribe(
        () => {
          this.errorMessage = undefined;
          this.updateLoadingState(false);
          this.actions = this.createActions();
        },
        (error: string) => {
          this.errorMessage = error;
          this.updateLoadingState(false);
          this.actions = this.createActions();
        }
      );
  }

  private loadDriverDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsDataService.loadDriverDocumentTypes().pipe(
      tap((documentTypes: DocumentType[]) => {
        this.errorMessage = undefined;
        this.updateLoadingState(false);
        this.updateActionUpLoad();
        this.driverDocumentTypes = documentTypes;
      }),
      catchError((error: string) => {
        this.errorMessage = ERROR_MESSAGE;
        this.updateLoadingState(false);
        this.updateActionUpLoad();
        throw error;
      })
    );
  }

  onLogout(): void {
    this.recruiterDataService.logout();
  }

  get id() {
    return this.form.get('id');
  }
  get identityStatus() {
    return this.form.get('identityStatus');
  }
  get email() {
    return this.form.get('email');
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }
  get phoneNumber() {
    return this.form.get('phoneNumber');
  }
  get employments(): FormArray {
    return this.form.controls.employments as FormArray;
  }
  get education(): FormGroup {
    return this.form.controls.education as FormGroup;
  }
  get experience(): FormGroup {
    return this.form.controls.experience as FormGroup;
  }

  private createForm(): FormGroup {
    return this.fb.group({
      id: [''],
      identityStatus: [''],
      email: [''],
      firstName: [''],
      lastName: [''],
      phoneNumber: [''],
      address: [''],
      issuingAuthority: [''],
      licenseType: [''],
      employments: this.fb.array([]),
      education: this.createEducation(),
      experience: this.createExperience()
    });
  }

  private updateActionUpLoad() {
    if (this.actions && this.actions[0] && 'disabled' in this.actions[0]) {
      this.actions[0].disabled = this.uploadDisabled();
    }
  }

  private createActions(): TabActionLink[] {
    const actions: TabActionLink[] = [];

    actions.push({
      title: 'UPLOAD',
      disabled: this.loading,
      clickHandler: () => this.onDocumentUpload()
    } as TabActionLink);

    return actions;
  }

  private populateForm(driver: Driver): void {
    this.form.patchValue({
      id: driver.id,
      identityStatus: driver.identityStatus,
      email: driver.email,
      firstName: driver.firstName,
      lastName: driver.lastName,
      address: driver.address,
      issuingAuthority: driver.issuingAuthority,
      licenseType: driver.licenseType,
      phoneNumber: driver.phoneNumber,

      education: {
        id: driver.education ? driver.education.id : undefined,
        highestGradeCompleted: driver.education ? driver.education.highestGradeCompleted : undefined,
        lastSchoolAttended: driver.education ? driver.education.lastSchoolAttended : undefined,
        certification: driver.education ? driver.education.certification : undefined,
        specialCourses: driver.education ? driver.education.specialCourses : undefined
      },
      experience: {
        id: driver.experience ? driver.experience.id : undefined,
        drivingGraduate: driver.experience ? driver.experience.drivingGraduate : undefined,
        companyDriver: driver.experience ? driver.experience.companyDriver : undefined,
        studentDriver: driver.experience ? driver.experience.studentDriver : undefined,
        ownerOperator: driver.experience ? driver.experience.ownerOperator : undefined,
        yearsOfExperience: driver.experience ? driver.experience.yearsOfExperience : undefined
      }
    });

    if (driver.employment) {
      const employmentFormsArray = this.form.controls.employments as FormArray;
      driver.employment.forEach((employment: Employment) => {
        employmentFormsArray.push(this.createEmployment(employment));
      });
    }

    let truckType = driver.experience ? this.findTruckType(driver.experience.truckTypes, 'straightTruck') : undefined;
    if (truckType) {
      const experienceFormGroup = this.form.controls.experience as FormGroup;
      const truckTypeFormGroup = experienceFormGroup.controls.straightTruck as FormGroup;
      truckTypeFormGroup.patchValue({
        flat: truckType ? truckType.flat : undefined,
        tank: truckType ? truckType.tank : undefined,
        van: truckType ? truckType.van : undefined,
        milesDriven: truckType ? truckType.milesDriven : undefined,
        other: truckType ? truckType.other : undefined,
        fromDate: truckType ? this.formatDate(truckType.fromDate) : undefined,
        toDate: truckType ? this.formatDate(truckType.toDate) : undefined,
        type: truckType ? truckType.type : undefined,
        displayType: truckType ? truckType.displayType : undefined
      });
    }

    truckType = driver.experience
      ? this.findTruckType(driver.experience.truckTypes, 'tractorAndSemiTrailer')
      : undefined;
    if (truckType) {
      const experienceFormGroup = this.form.controls.experience as FormGroup;
      const truckTypeFormGroup = experienceFormGroup.controls.tractor as FormGroup;
      truckTypeFormGroup.patchValue({
        flat: truckType ? truckType.flat : false,
        tank: truckType ? truckType.tank : false,
        van: truckType ? truckType.van : false,
        milesDriven: truckType ? truckType.milesDriven : undefined,
        other: truckType ? truckType.other : undefined,
        fromDate: truckType ? this.formatDate(truckType.fromDate) : undefined,
        toDate: truckType ? this.formatDate(truckType.toDate) : undefined,
        type: truckType ? truckType.type : undefined,
        displayType: truckType ? truckType.displayType : undefined
      });
    }

    truckType = driver.experience ? this.findTruckType(driver.experience.truckTypes, 'tractorTwoTrailers') : undefined;
    if (truckType) {
      const experienceFormGroup = this.form.controls.experience as FormGroup;
      const truckTypeFormGroup = experienceFormGroup.controls.tractorTwo as FormGroup;
      truckTypeFormGroup.patchValue({
        flat: truckType ? truckType.flat : false,
        tank: truckType ? truckType.tank : false,
        van: truckType ? truckType.van : false,
        milesDriven: truckType ? truckType.milesDriven : undefined,
        other: truckType ? truckType.other : undefined,
        fromDate: truckType ? this.formatDate(truckType.fromDate) : undefined,
        toDate: truckType ? this.formatDate(truckType.toDate) : undefined,
        type: truckType ? truckType.type : undefined,
        displayType: truckType ? truckType.displayType : undefined
      });
    }

    truckType = driver.experience ? this.findTruckType(driver.experience.truckTypes, 'others') : undefined;
    if (truckType) {
      const experienceFormGroup = this.form.controls.experience as FormGroup;
      const truckTypeFormGroup = experienceFormGroup.controls.others as FormGroup;
      truckTypeFormGroup.patchValue({
        flat: truckType ? truckType.flat : false,
        tank: truckType ? truckType.tank : false,
        van: truckType ? truckType.van : false,
        milesDriven: truckType ? truckType.milesDriven : undefined,
        other: truckType ? truckType.other : undefined,
        fromDate: truckType ? this.formatDate(truckType.fromDate) : undefined,
        toDate: truckType ? this.formatDate(truckType.toDate) : undefined,
        type: truckType ? truckType.type : undefined,
        displayType: truckType ? truckType.displayType : undefined
      });
    }
  }

  private createEmployment(employment?: Employment): FormGroup {
    return this.fb.group({
      id: employment ? employment.id : undefined,
      employer: employment ? employment.employer : undefined,
      fromDate: employment ? this.formatDate(employment.fromDate) : undefined,
      toDate: employment ? this.formatDate(employment.toDate) : undefined,
      position: employment ? employment.position : undefined
    });
  }

  private createEducation(education?: Education): FormGroup {
    return this.fb.group({
      id: education ? education.id : undefined,
      highestGradeCompleted: education ? education.highestGradeCompleted : undefined,
      lastSchoolAttended: education ? education.lastSchoolAttended : undefined,
      certification: education ? education.certification : undefined,
      specialCourses: education ? education.specialCourses : undefined
    });
  }

  private createExperience(experience?: DrivingExperience): FormGroup {
    return this.fb.group({
      id: experience ? experience.id : undefined,
      drivingGraduate: experience ? experience.drivingGraduate : undefined,
      currentStatus: experience ? experience.currentStatus : undefined,
      companyDriver: experience ? experience.companyDriver : undefined,
      studentDriver: experience ? experience.studentDriver : undefined,
      ownerOperator: experience ? experience.ownerOperator : undefined,
      yearsOfExperience: experience ? experience.yearsOfExperience : undefined,
      straightTruck: this.createTruckType(
        experience ? this.findTruckType(experience.truckTypes, 'straightTruck') : undefined
      ),
      tractor: this.createTruckType(
        experience ? this.findTruckType(experience.truckTypes, 'tractorAndSemiTrailer') : undefined
      ),
      tractorTwo: this.createTruckType(
        experience ? this.findTruckType(experience.truckTypes, 'tractorTwoTrailers') : undefined
      ),
      others: this.createTruckType(experience ? this.findTruckType(experience.truckTypes, 'others') : undefined)
    });
  }

  private createTruckType(truckType?: TruckType): FormGroup {
    return this.fb.group({
      flat: truckType ? truckType.flat : undefined,
      tank: truckType ? truckType.tank : undefined,
      van: truckType ? truckType.van : undefined,
      milesDriven: truckType ? truckType.milesDriven : undefined,
      other: truckType ? truckType.other : undefined,
      fromDate: truckType ? this.formatDate(truckType.fromDate) : undefined,
      toDate: truckType ? this.formatDate(truckType.toDate) : undefined,
      type: truckType ? truckType.type : undefined,
      displayType: truckType ? truckType.displayType : undefined
    });
  }

  private findTruckType(truckTypes, type): TruckType {
    if (!truckTypes) {
      return undefined;
    }

    return truckTypes.find((truckType: TruckType) => truckType && truckType.type === type);
  }

  private updateLoadingState(loading: boolean): void {
    this.loading = loading;
    if (this.driverDocumentListComponent) {
      this.driverDocumentListComponent.setLoadingState(loading);
    }
  }

  formatDate(date: any): string {
    const formattedDate = moment(date, ['YYYYMMDD']).format('MMM DD, YYYY');
    return formattedDate;
  }
}
