export class Notification {
  id: string;
  name: string;
  type: string;
  status: string;
  verifiedDate: string;
  source: string;
  createdDate: string;
  generationDate: string;
  validToDate: string;
}
