import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Notification } from '@fleetoperate/shared/driver/data-access-driver';
import { DateTimeService } from '@fleetoperate/shared/util';
import { DriverDocument } from '@fleetoperate/shared/documents/data-access-documents';

interface NotificationModel extends Notification {
  formattedCreatedDate: string;
  formattedReporGeneratedDate: string;
}
@Component({
  selector: 'fleetoperate-driver-document-list',
  templateUrl: './driver-document-list.component.html',
  styleUrls: ['./driver-document-list.component.scss']
})
export class DriverDocumentListComponent implements OnInit, OnChanges {
  @Input() dataSource: NotificationModel[];
  @Input() loading: boolean;
  @Input() driverDocuments: DriverDocument[];
  @Output() downloadDocument = new EventEmitter();
  notificationModel: NotificationModel;
  displayedColumns = ['type', 'reportGenerated', 'uploaded', 'createdBy', 'action'];

  constructor(private readonly dateTimeService: DateTimeService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((notification: NotificationModel) => {
        this.loading = false;
        const localGenerationDate = (notification.formattedReporGeneratedDate = notification
          ? this.dateTimeService.getLocalDateTime(notification.generationDate)
          : '');
        const localCreatedDate = (notification.formattedCreatedDate = notification
          ? this.dateTimeService.getLocalDateTime(notification.createdDate)
          : '');
        notification.formattedReporGeneratedDate = this.dateTimeService.printDateTime(localGenerationDate);
        notification.formattedCreatedDate = this.dateTimeService.printDateTime(localCreatedDate);
      });
    } else {
      this.loading = false;
    }
  }

  onDriverDocumentDownload(documentID: string) {
    this.setLoadingState(true);
    this.downloadDocument.emit(documentID);
  }

  setLoadingState(loading: boolean): void {
    this.loading = loading;
  }
}
