<div fxLayout="column">
  <div fxLayout class="options">
    <mat-radio-group class="full-width options" fxLayout="row-reverse" [formControl]="timeFilter">
      <mat-radio-button
        disableRipple="true"
        class="button secondary-button"
        *ngFor="let timeOption of this.timeOptions"
        fx-layout-align="end"
        [value]="timeOption.value"
      >
        <p class="full-width center-content">{{ timeOption.text }}</p>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div fxLayout="column" class="margin-bottom-md margin-right-sm margin-bottom-md margin-left-sm">
    <span *ngIf="errorMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ errorMessage }}</span>
    <table mat-table [dataSource]="errorsList" class="full-width mat-elevation-z8">
      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let element">{{ element.affectedUser }}</td>
      </ng-container>

      <!-- Context Column -->
      <ng-container matColumnDef="context">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let element">{{ element.attemptedAction }}</td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date & Time</th>
        <td mat-cell *matCellDef="let element">{{ element.capturedTime }}</td>
      </ng-container>

      <!-- Error Message -->
      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>Error Message</th>
        <td mat-cell *matCellDef="let element">{{ element.error.message }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div fxFlexAlign="center" class="table-message">
      <mat-spinner class="margin-top-md" *ngIf="loading"></mat-spinner>
      <p *ngIf="(!errorsList || errorsList.length <= 0) && !loading">No errors found.</p>
    </div>
  </div>
</div>
