import { Injectable } from '@angular/core';
import { DriverDataModule } from './driver-data.module';
import { Observable } from 'rxjs';
import { Driver } from '../models/driver.model';
import { DriverApiService } from './driver-api.service';
import { map } from 'rxjs/operators';
import { Response } from '@fleetoperate/shared/data-access';

@Injectable({
  providedIn: DriverDataModule
})
export class DriverDataService {

  constructor(private readonly driversAPIService: DriverApiService) { }

  loadDriver(id: string): Observable<Driver> {
    return this.driversAPIService.getDriver(id).pipe(
      map((response: Response) => response.data as Driver),
    );
  }

  loadDrivers(): Observable<Driver[]> {
    return this.driversAPIService.getDrivers().pipe(
      map((response: Response) => response.data as Driver[]),
    );
  }
}
