import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DocumentsDataService } from '@fleetoperate/shared/documents/data-access-documents';

const RequiredFieldMessage = 'You must enter a value';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;

interface DialogData {
  driverDocumentTypes: DocumentType[];
  driverID: string;
}

@Component({
  selector: 'fleetoperate-document-upload-dialog',
  templateUrl: './document-upload-dialog.component.html',
  styleUrls: ['./document-upload-dialog.component.scss']
})

export class DocumentUploadDialogComponent implements OnInit {
  driverDocumentForm: FormGroup;
  successMessage: string;
  errorMessage: string;
  maxDate: Date;
  closingDialog: boolean;

  constructor(public dialogRef: MatDialogRef<DocumentUploadDialogComponent>,
    private readonly cd: ChangeDetectorRef,
    private readonly documentsDataService: DocumentsDataService,
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.closingDialog = false;
    this.driverDocumentForm = this.createDriverDocumentForm();
  }

  ngOnInit() {
    this.maxDate = new Date();
  }

  get reportGenerationDate() { return this.driverDocumentForm.get('reportGenerationDate'); }
  get source() { return this.driverDocumentForm.get('source'); }
  get documentType() { return this.driverDocumentForm.get('documentType'); }

  getDocumentTypeErrorMessage() {
    const errors = this.documentType.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getReportGenerationDateErrorMessage() {
    const errors = this.reportGenerationDate.errors;
    return errors.required ? RequiredFieldMessage : '';
  }

  getSourceErrorMessage() {
    const errors = this.source.errors;
    return errors.required ? RequiredFieldMessage :
      errors.maxlength ? MaximumLengthFieldMessage(errors.maxlength.requiredLength) : '';
  }

  createDriverDocumentForm(): FormGroup {
    return this.fb.group({
      driverConsentReceived: [false, Validators.required],
      documentType: [undefined],
      source: ['', [Validators.required, Validators.maxLength(50)]],
      reportGenerationDate: ['', Validators.required],
      validToDate: [''],
      file: [null, Validators.required],
      filename: [undefined]
    });
  }

  onFileChange(event: any) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.driverDocumentForm.patchValue({
          file: file,
          filename: file.name
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

}
