<div *ngIf="group" [formGroup]="group">

  <div fxLayout="column" class="margin-left-lg">

    <div fxLayout="row" class="display-type-and-miles-driven">
      <h5 fxLayout="column" class="margin-right-lg"> {{group.value.displayType}}</h5>

      <span *ngIf="group.value.milesDriven;else zeroMiles" fxLayout="column"
        class="margin-top-md miles-driven">{{group.value.milesDriven}} miles driven</span>
      <ng-template #zeroMiles>
        <span class="margin-top-md">0 miles driven</span>
      </ng-template>
    </div>
    <div fxLayout="column" class="margin-bottom-sm">
      <mat-checkbox [disabled]="true" formControlName="van">Van</mat-checkbox>
      <mat-checkbox [disabled]="true" formControlName="flat">Flat</mat-checkbox>
      <mat-checkbox [disabled]="true" formControlName="tank">Tank</mat-checkbox>
    </div>

    <div *ngIf="group.value.fromDate && group.value.fromDate && group.value.fromDate != invalidDate && group.value.toDate != invalidDate; else noDates" fxLayout="row" class="dates">
      <div fxLayout="column">
        <span class="margin-bottom-sm">From</span>
        <span>{{group.value.fromDate}}</span>
      </div>

      <div fxLayout="column">
        <span class="margin-bottom-sm">To </span>
        <span>{{group.value.toDate}}</span>
      </div>
    </div>
    <ng-template #noDates>
      <div class="no-dates"></div>
    </ng-template>
  </div>


</div>