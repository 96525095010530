<div *ngIf="group" [formGroup]="group">
  <h4>Driving Experience</h4>
  <div fxLayout="row" fxLayout.lt-md="column">
    <div fxLayout="column">
      <mat-form-field class="long-form-field margin-left-lg">
        <input matInput [readonly]="true" placeholder="Graduating from Driving school"
          formControlName="drivingGraduate">
      </mat-form-field>
      <mat-form-field class="margin-left-lg">
        <input matInput [readonly]="true" placeholder="Years of experience" formControlName="yearsOfExperience">
      </mat-form-field>
    </div>
    <div fxLayout="column" class="margin-left-lg">
      <mat-form-field>

        <div *ngIf="companyDriver;else showStudentDriver">
          <input matInput [readonly]="true" value="Company Driver">
        </div>

        <ng-template #showStudentDriver>
          <input matInput [readonly]="true" value="Student Driver">
        </ng-template>

        <mat-placeholder>Current Status</mat-placeholder>
      </mat-form-field>
    </div>
  </div>
</div>