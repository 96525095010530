import { Education } from './education.model';
import { Employment } from './employment.model';
import { DrivingExperience } from './driving-experience.model';
import { ProfilePicture } from './profile-picture.model';
import { Preferences } from './preferences.model';
import { Notification } from './notification.model';

export class Driver {
  experience: DrivingExperience;
  education: Education;
  email: string;
  driverID: string;
  employment: Employment[];
  firstName: string;
  id: string;
  address: string;
  identityStatus: string;
  lastName: string;
  phoneNumber: string;
  preference: Preferences;
  licenseType: string;
  issuingAuthority: string;
  profilePicture: ProfilePicture;
  notification: Notification[];
}
