import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { RecruiterFeatureShellModule } from '@fleetoperate/delivery-recruiter/feature-shell';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppState } from './state/app.state';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppState], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    UiKitMaterialModule,
    RecruiterFeatureShellModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl
    },
    {
      provide: 'appName',
      useValue: 'recruiter'
    },
    {
      provide: 'environmentData',
      useValue: environment
    },
    {
      provide: 'registrationApiUrl',
      useValue: undefined
    },
    {
      provide: 'signinSuccessPath',
      useValue: environment.signinSuccessPath
    },
    {
      provide: 'loggingEnabled',
      useValue: environment.loggingEnabled
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('done', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/done-24px.svg'));
    iconRegistry.addSvgIcon(
      'autorenew',
      sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/autorenew-24px.svg')
    );
    iconRegistry.addSvgIcon('block', sanitizer.bypassSecurityTrustResourceUrl('assets/img/icons/block-24px.svg'));
  }
}
