import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipperSearchModule } from './shipper-search/shipper-search.module';
import { RecruiterFeatureShipperRoutingModule } from './recruiter-feature-shipper-routing.module';
import { RecruiterFeatureDriverModule } from '@fleetoperate/delivery-recruiter/feature-driver';
import { ShipmentListModule } from './shipment-list/shipment-list.module';
import { SharedDeliveryShipperDataAccessShipperModule } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipperProdSupportModule } from './shipper-prod-support/shipper-prod-support.module';

@NgModule({
  imports: [
    CommonModule,
    RecruiterFeatureDriverModule,
    ShipperSearchModule,
    ShipmentListModule,
    ShipperProdSupportModule,
    SharedDeliveryShipperDataAccessShipperModule,
    RecruiterFeatureShipperRoutingModule
  ]
})
export class RecruiterFeatureShipperModule {}
