import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverSearchModule } from './driver-search/driver-search.module';
import { RecruiterFeatureDriverRoutingModule } from './recruiter-feature-driver-routing.module';
import { DriverProfileModule } from './driver-profile/driver-profile.module';
import { RecruiterFeatureErrorsModule } from '@fleetoperate/delivery-recruiter/feature-errors';
import { DriverProdSupportModule } from './driver-prod-support/driver-prod-support.module';

@NgModule({
  imports: [
    CommonModule,
    RecruiterFeatureErrorsModule,
    DriverSearchModule,
    DriverProfileModule,
    DriverProdSupportModule,
    RecruiterFeatureDriverRoutingModule
  ]
})
export class RecruiterFeatureDriverModule {}
