import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsComponent } from './errors/errors.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatTableModule,
  MatRadioModule,
  MatProgressSpinnerModule
} from '@angular/material';
import { ErrorDataModule } from '@fleetoperate/shared/data-access-error';

@NgModule({
  declarations: [ErrorsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    ErrorDataModule
  ],
  exports: [ErrorsComponent]
})
export class ErrorsModule {}
