import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecruiterDataModule } from './recruiter-data/recruiter-data.module';

@NgModule({
  imports: [
    CommonModule,
    RecruiterDataModule
  ]
})
export class SharedRecruiterDataAccessRecruiterModule {}
