import { RecruiterStateModel } from './recruiter-data-state';

export class SetRecruiter {
  static readonly type = '[RecruiterData] SetRecruiter';
  constructor(public payload: RecruiterStateModel) {}
}

export class ClearRecruiter {
  static readonly type = '[RecruiterData] ClearRecruiter';
  constructor() {}
}
