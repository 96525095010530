import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { RecruiterDataState } from './state/recruiter-data-state';
import { StorageModule } from '@fleetoperate/shared/util';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StorageModule,
    NgxsModule.forFeature([RecruiterDataState])
  ]
})
export class RecruiterDataModule { }
