<div *ngIf="group" [formGroup]="group">
  <h4>Driving License Details</h4>
  <div class="license-details" fxLayout="row" fxLayout.lt-md="column">
    <div class="long-form-field margin-left-lg">
      <mat-form-field>
        <input matInput [readonly]="true" placeholder="Name" formControlName="firstName">
      </mat-form-field>
      <mat-form-field>
        <input matInput [readonly]="true" placeholder="Validity">
      </mat-form-field>
    </div>
    <div class="long-form-field margin-left-lg">
      <mat-form-field>
        <input matInput [readonly]="true" placeholder="Class" formControlName="licenseType">
      </mat-form-field>
      <mat-form-field>
        <input matInput [readonly]="true" placeholder="Issuing Authority" formControlName="issuingAuthority">
      </mat-form-field>
    </div>
  </div>
</div>