import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DriverSearchService } from '../driver-search.service';
import { MatTableDataSource } from '@angular/material/table';
import { Driver } from '@fleetoperate/shared/driver/data-access-driver';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { ROUTE_DRIVER_PROD_SUPPORT } from '../../shared/routes';
import { ROUTE_SHIPPER_SEARCH } from '@fleetoperate/delivery-recruiter/feature-shipper';

const ERROR_MESSAGE = 'There was an error. Please try again.';
@Component({
  selector: 'app-driver-search',
  templateUrl: './driver-search.component.html',
  styleUrls: ['./driver-search.component.scss']
})
export class DriverSearchComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  idCntrl = new FormControl('');
  driversList: Driver[];
  filteredDrivers: Driver[];
  displayedColumns: string[];
  dataSource: any;
  title: string;
  user: User;

  constructor(
    private readonly driverSearchService: DriverSearchService,
    private readonly recruiterDataService: RecruiterDataService,
    private readonly router: Router
  ) {
    this.title = 'Driver Search';
    this.displayedColumns = ['id', 'firstName', 'address', 'identityCheckStatus'];
  }

  ngOnInit() {
    this.user = {
      name: this.recruiterDataService.getRecruiter() && this.recruiterDataService.getRecruiter().email
    };
    this.loadDrivers();
  }

  private loadDrivers() {
    this.loading = true;
    this.driverSearchService
      .findDrivers()
      .pipe(first())
      .subscribe(
        (drivers: Driver[]) => {
          this.errorMessage = undefined;
          this.loading = false;
          this.driversList = drivers;
          this.filteredDrivers = drivers;
          this.idCntrl.reset();
        },
        (error: string) => {
          this.errorMessage = ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  onSearchInput() {
    this.filterDrivers();
  }

  onNavigateToProdSupport(): void {
    this.router.navigate([ROUTE_DRIVER_PROD_SUPPORT]);
  }

  onNavigateToShipperSearch(): void {
    this.router.navigate([ROUTE_SHIPPER_SEARCH]);
  }

  filterDrivers() {
    let searchText = this.idCntrl.value;
    if (searchText && this.driversList) {
      this.filteredDrivers = this.driversList.filter(driver => {
        if (!driver) {
          return;
        }
        if (
          this.searchDriverIds(driver.driverID, searchText) ||
          this.searchFirstName(driver.firstName, searchText) ||
          this.searchStatus(driver.identityStatus, searchText) ||
          this.searchAddress(driver.address, searchText)
        ) {
          return driver;
        }
      });
    } else {
      this.filteredDrivers = this.driversList;
    }
  }

  private searchDriverIds(driverID: string, searchText: string): boolean {
    if (driverID && searchText) {
      return driverID.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchFirstName(firstName: string, searchText: string): boolean {
    if (firstName && searchText) {
      return firstName.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchStatus(status: string, searchText: string): boolean {
    if (status && searchText) {
      return status.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchAddress(address: string, searchText: string): boolean {
    if (address && searchText) {
      return address.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  onLogout(): void {
    this.recruiterDataService.logout();
  }
}
