<mat-card fxLayout="row wrap" fxLayoutGap="16px">
  <div fxLayout fxLayoutGap="10px" *ngFor="let driverDocument of driverDocuments">
    <mat-icon fxFlexAlign="center" [svgIcon]="driverDocument.icon" [color]="driverDocument.iconColor"> </mat-icon>
    <span class="driverDocumentTypes" fxFlexAlign="center">{{ driverDocument.name }}</span>
  </div>
</mat-card>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>

    <ng-container matColumnDef="reportGenerated">
      <th mat-header-cell *matHeaderCellDef>Report Generated</th>
      <td mat-cell *matCellDef="let element">{{ element.formattedReporGeneratedDate }}</td>
    </ng-container>

    <ng-container matColumnDef="uploaded">
      <th mat-header-cell *matHeaderCellDef>Uploaded</th>
      <td mat-cell *matCellDef="let element">{{ element.formattedCreatedDate }}</td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created By</th>
      <td mat-cell *matCellDef="let element">{{ element.createdBy }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" fxLayoutGap="5px">
        <button
          mat-raised-button
          *ngIf="element.status === 'uploaded'"
          type="button"
          [disabled]="loading"
          (click)="onDriverDocumentDownload(element.id)"
        >
          Download
        </button>
        <div class="showPending margin-left-md" *ngIf="element.status === 'pending'">
          Pending
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div class="table-message">
    <mat-spinner class="margin-top-md" *ngIf="loading"></mat-spinner>
    <p *ngIf="(!dataSource || dataSource.length <= 0) && !loading">No documents found.</p>
  </div>
</div>
