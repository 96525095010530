import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { StorageService } from '@fleetoperate/shared/util';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { RecruiterDataModule } from './recruiter-data.module';
import { Recruiter } from '../models/recruiter';
import { RecruiterDataState, RecruiterStateModel } from './state/recruiter-data-state';
import { RecruiterApiService } from './recruiter-api.service';
import { SetRecruiter, ClearRecruiter } from './state/recruiter-data-actions';

const KEY_RECRUITER = 'delivery-recruiter';

@Injectable({
  providedIn: RecruiterDataModule
})
export class RecruiterDataService {
  constructor(
    private readonly router: Router,
    private readonly recruiterApi: RecruiterApiService,
    private readonly storageService: StorageService,
    private readonly store: Store,
    private readonly authService: AuthService
  ) {
    const recruiter = this.storageService.find(KEY_RECRUITER) as Recruiter;
    if (recruiter) {
      const recruiterStateModel = this.createRecruiterStateModel(recruiter);
      this.store.dispatch([new SetRecruiter(recruiterStateModel)]);
    }
  }

  loadRecruiter(): Observable<Recruiter> {
    return this.recruiterApi.getRecruiter().pipe(
      map((response: any) => response.data),
      tap((recruiter: Recruiter) => {
        const recruiterStateModel = this.createRecruiterStateModel(recruiter);
        this.store.dispatch([new SetRecruiter(recruiterStateModel)]);
        this.storageService.store(KEY_RECRUITER, recruiter);
        return recruiter;
      })
    );
  }

  getRecruiter(): Recruiter {
    const recruiterStateModel = this.store.selectSnapshot(RecruiterDataState.recruiter);

    if (!recruiterStateModel || !recruiterStateModel.id) {
      return;
    }

    const recruiter = this.createRecruiter(recruiterStateModel);
    return recruiter;
  }

  logout(): void {
    this.authService.unauthenticate();
    this.clearRecruiter();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private clearRecruiter(): void {
    this.store.dispatch([new ClearRecruiter()]);
    this.storageService.remove(KEY_RECRUITER);
  }

  private createRecruiterStateModel(recruiter: Recruiter): RecruiterStateModel {
    const recruiterStateModel = {
      id: recruiter.id,
      status: recruiter.status,
      firstName: recruiter.firstName,
      lastName: recruiter.lastName,
      email: recruiter.email,
      password: recruiter.password
    } as RecruiterStateModel;
    return recruiterStateModel;
  }

  private createRecruiter(recruiterStateModel: RecruiterStateModel): Recruiter {
    const recruiter = {
      id: recruiterStateModel.id,
      status: recruiterStateModel.status,
      firstName: recruiterStateModel.firstName,
      lastName: recruiterStateModel.lastName,
      email: recruiterStateModel.email,
      password: recruiterStateModel.password
    } as Recruiter;
    return recruiter;
  }
}
