<fleetoperate-fleetoperate-toolbar [data]="user" (logout)="onLogout()"></fleetoperate-fleetoperate-toolbar>
<fleetoperate-header [title]="title" class="margin-left-md margin-right-md"></fleetoperate-header>

<div fxLayout class="options">
  <form class="driver-search-filters margin-right-sm margin-left-sm" fxFlex>
    <mat-form-field fxFlexAlign="center">
      <input matInput placeholder="Search by ID" [formControl]="idCntrl" (input)="onSearchInput()" #idInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </form>
  <div fxLayout fxLayout.lt-md="column">
    <button
      class="button secondary-button"
      (click)="onNavigateToProdSupport()"
      fx-layout-align="end"
      mat-stroked-button
    >
      Prod Support
    </button>
    <button
      class="button primary-button"
      (click)="onNavigateToShipperSearch()"
      fx-layout-align="end"
      mat-stroked-button
    >
      Shippers
    </button>
  </div>
</div>
<div fxLayout="column" class="margin-right-sm margin-bottom-md margin-left-sm ">
  <span *ngIf="errorMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ errorMessage }}</span>
  <table mat-table [dataSource]="filteredDrivers" class="full-width mat-elevation-z8">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Driver id</th>
      <td mat-cell *matCellDef="let element">{{ element.driverID }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>Driver Name</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/driver', element.id]">{{ element.firstName }} {{ element.lastName }}</a>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element">{{ element.address }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="identityCheckStatus">
      <th mat-header-cell *matHeaderCellDef>Identity Check Status</th>
      <td mat-cell *matCellDef="let element">{{ element.identityStatus }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div fxFlexAlign="center" class="table-message">
    <mat-spinner class="margin-top-md" *ngIf="loading"></mat-spinner>
    <p *ngIf="(!filteredDrivers || filteredDrivers.length <= 0) && !loading">No drivers found.</p>
  </div>
</div>
