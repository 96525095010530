import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { RecruiterFeatureShellModule } from '../recruiter-feature-shell.module';

@Injectable({
  providedIn: RecruiterFeatureShellModule
})
export class RecruiterGuardService implements CanActivate {
  constructor(private readonly router: Router, private readonly recruiterDataService: RecruiterDataService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const recruiter = this.recruiterDataService.getRecruiter();

    if (recruiter) {
      return true;
    }

    this.recruiterDataService.logout();
    this.navigateToSignIn();
    return false;
  }

  private navigateToSignIn(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
