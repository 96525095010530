import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { RecruiterDataModule } from './recruiter-data.module';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Recruiter } from '../models/recruiter';

const PATH_RECRUITERS_ID = '/recruiters/{id}';

@Injectable({
  providedIn: RecruiterDataModule
})
export class RecruiterApiService {

  constructor(private readonly api: FleetoperateApiService,
              private readonly authService: AuthService,
              @Inject('apiUrl') private apiUrl: string,
              private readonly http: HttpClient) { }

  getRecruiter(): Observable<Response> {
    // const path = PATH_RECRUITERS_ID.replace('{id}', this.authService.userID);
    // return this.authService.isAuthenticated().pipe(
    //   mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token))
    // );
    const recruiter = {
      id: this.authService.userID,
      status: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      password: undefined
    } as Recruiter;

    const response = {
      status: 200,
      data: recruiter
    } as Response;

    return of(response);
  }
}
