import { Selector, State, Action, StateContext } from '@ngxs/store';
import { SetRecruiter, ClearRecruiter } from './recruiter-data-actions';

const recruiterDefault = {
  id: undefined,
  status: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  password: undefined
} as RecruiterStateModel;

export interface RecruiterStateModel {
  id: string;
  status: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface RecruiterDataStateModel {
  recruiter: RecruiterStateModel;
}

@State<RecruiterDataStateModel>({
  name: 'recruiterdata',
  defaults: {
    recruiter: recruiterDefault
  }
})
export class RecruiterDataState {

  constructor() { }

  @Selector() static recruiter(state: RecruiterDataStateModel) {
    return state.recruiter;
  }

  @Action(SetRecruiter)
  setRecruiter(ctx: StateContext<RecruiterDataStateModel>, { payload }: SetRecruiter) {
      ctx.patchState({ recruiter: payload });
  }

  @Action(ClearRecruiter)
  clearRecruiter(ctx: StateContext<RecruiterDataStateModel>, action: ClearRecruiter) {
      ctx.patchState({ recruiter: undefined });
  }
}
