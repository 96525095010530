<form *ngIf="group" [formGroup]="group">
  <h4>Employment History</h4>
  <div fxLayout="column" formArrayName="employments"
    *ngIf="group.get('employments').controls.length > 0;else showNoHistory">
    <div *ngFor="let employment of group.get('employments').controls; let i = index;" class="margin-left-lg">
      <div [formGroupName]="i" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
        <div fxLayout="column">
          <mat-form-field class="long-form-field">
            <input matInput [readonly]="true" placeholder="Employer" formControlName="employer">
          </mat-form-field>

          <mat-form-field class='long-form-field'>
            <div fxLayout="row">
              <input matInput [readonly]="true" formControlName="fromDate">
              <span class="margin-right-md"> - </span>
              <input matInput [readonly]="true" formControlName="toDate">
            </div>
            <mat-placeholder>Duration</mat-placeholder>
          </mat-form-field>
        </div>
        <mat-form-field class="long-form-field">
          <input matInput [readonly]="true" placeholder="Position held" formControlName="position">
        </mat-form-field>
      </div>
    </div>
  </div>
  <ng-template #showNoHistory>
    <span class="margin-left-lg">No work history</span>
  </ng-template>
</form>