import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorsService } from '../errors.service';
import { Error } from '@fleetoperate/shared/data-access-error';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { DateTimeService } from '@fleetoperate/shared/util';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const ONE_HOUR = '60m';
const ONE_DAY = '24h';
const THREE_DAYS = '3d';
const SEVEN_DAYS = '7d';

@Component({
  selector: 'fleetoperate-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  timeFilter = new FormControl(ONE_HOUR);
  errorsList: Error[];
  displayedColumns: string[];
  title: string;
  dataSource: any;
  user: User;
  timeOptions = [
    { value: ONE_HOUR, text: ONE_HOUR },
    { value: ONE_DAY, text: ONE_DAY },
    { value: THREE_DAYS, text: THREE_DAYS },
    { value: SEVEN_DAYS, text: SEVEN_DAYS }
  ];
  backAction: Function;
  private logoutSubscription: Subscription;

  @Input() logoutEvent: Observable<void>;

  @Input() errorType: string;

  constructor(
    private readonly router: Router,
    private readonly errorsService: ErrorsService,
    private readonly recruiterDataService: RecruiterDataService,
    private readonly dateTimeService: DateTimeService
  ) {
    this.title = 'Prod Support';
    this.displayedColumns = ['id', 'context', 'date', 'message'];
  }

  ngOnInit() {
    this.logoutSubscription = this.logoutEvent.subscribe(() => this.onLogout());
    this.user = {
      name: this.recruiterDataService.getRecruiter() && this.recruiterDataService.getRecruiter().email
    };
    this.timeFilter.valueChanges.subscribe(() => this.loadErrors());
    this.timeFilter.setValue(ONE_HOUR);
  }

  private loadErrors() {
    this.loading = true;
    this.errorsService
      .getErrors(this.timeFilter.value)
      .pipe(first())
      .subscribe(
        (errors: Error[]) => {
          this.loading = false;
          this.errorMessage = undefined;
          this.errorsList = errors.map(error => ({
            ...error,
            capturedTime: this.dateTimeService.getLocalDateTime(error.capturedTime)
          }));
        },
        (error: string) => {
          this.loading = false;
          this.errorMessage = ERROR_MESSAGE;
        }
      );
  }

  onLogout(): void {
    this.recruiterDataService.logout();
  }
}
