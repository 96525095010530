import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ShipperSearchService } from '../shipper-search.service';
import { Shipper } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { ROUTE_DRIVER_SEARCH } from '@fleetoperate/delivery-recruiter/feature-driver';
import { ROUTE_SHIPPER_PROD_SUPPORT } from '../../shared/routes';

const ERROR_MESSAGE = 'There was an error. Please try again.';

@Component({
  selector: 'fleetoperate-shipper-search',
  templateUrl: './shipper-search.component.html',
  styleUrls: ['./shipper-search.component.scss']
})
export class ShipperSearchComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  idCntrl = new FormControl('');
  shippersList: Shipper[];
  filteredShippers: Shipper[];
  displayedColumns: string[];
  title: string;
  dataSource: any;
  user: User;

  constructor(
    private readonly shipperSearchService: ShipperSearchService,
    private readonly recruiterDataService: RecruiterDataService,
    private readonly router: Router
  ) {
    this.title = 'Shipper Search';
    this.displayedColumns = ['id', 'company', 'address', 'phone', 'profileStatus'];
  }

  ngOnInit() {
    this.user = {
      name: this.recruiterDataService.getRecruiter() && this.recruiterDataService.getRecruiter().email
    };
    this.loadShippers();
  }

  private loadShippers() {
    this.loading = true;
    this.shipperSearchService
      .findShippers()
      .pipe(first())
      .subscribe(
        (shippers: Shipper[]) => {
          this.errorMessage = undefined;
          this.loading = false;
          this.shippersList = shippers;
          this.filteredShippers = shippers;
          this.idCntrl.reset();
        },
        (error: string) => {
          this.errorMessage = ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  onSearchInput() {
    this.filterShippers();
  }

  onNavigateToProdSupport(): void {
    this.router.navigate([ROUTE_SHIPPER_PROD_SUPPORT]);
  }

  onNavigateToDriverSearch(): void {
    this.router.navigate([ROUTE_DRIVER_SEARCH]);
  }

  private filterShippers() {
    let searchText = this.idCntrl.value;
    if (searchText && this.shippersList) {
      this.filteredShippers = this.shippersList.filter(shipper => {
        if (!shipper) {
          return;
        }
        if (
          this.searchField(shipper.id.toString(), searchText) ||
          this.searchField(shipper.companyName, searchText) ||
          this.searchField(shipper.address, searchText) ||
          this.searchField(shipper.phoneNumber, searchText) ||
          this.searchField(shipper.status, searchText)
        ) {
          return shipper;
        }
      });
    } else {
      this.filteredShippers = this.shippersList;
    }
  }

  private searchField(field: string, searchText: string): boolean {
    if (field && searchText) {
      return field.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  onLogout(): void {
    this.recruiterDataService.logout();
  }
}
