import { ENTER } from '@angular/cdk/keycodes';
import { Component, Input, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { first } from 'rxjs/internal/operators/first';
import {
  ShipperDataService,
  ShipmentDataService,
  Shipment
} from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { CurrencyFormatService, DateTimeService } from '@fleetoperate/shared/util';
import { Observable, Subscription } from 'rxjs';

interface ShipmentModel extends Shipment {
  color: string;
  buttonName: string;
  formattedPickupDateAndTime: string;
  formattedDeliveryDateAndTime: string;
  formattedOfferRate: string;
}

export const SHIPMENT_STATUS_OUT_FOR_DELIVERY = 'out for delivery';
export const SHIPMENT_STATUS_COMPLETED = 'completed';
export const SHIPMENT_STATUS_INACTIVE = 'inactive';

const ERROR_MESSAGE = 'There was an error. Please try again.';

@Component({
  selector: 'recruiter-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit, OnDestroy {
  separatorKeysCodes: number[] = [ENTER];
  addOnBlur = true;
  statusCtrl = new FormControl();
  idCntrl = new FormControl('');
  allStatus: string[] = [SHIPMENT_STATUS_OUT_FOR_DELIVERY, SHIPMENT_STATUS_INACTIVE, SHIPMENT_STATUS_COMPLETED];
  shipmentList: ShipmentModel[] = [];
  displayedColumns = ['shipmentId', 'mode', 'origin', 'destination', 'pickup', 'delivery', 'price'];
  filteredShipments: ShipmentModel[] = [];
  pageSizeOptions = [10, 20, 50];
  length = 0;
  totalLength = 0;
  shipmentsMessage: string;
  loading: boolean;
  errorMessage: String;
  private logoutSubscription: Subscription;

  @Input() logoutEvent: Observable<void>;

  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('idInput', { static: false }) idInput: ElementRef<HTMLInputElement>;

  constructor(
    private readonly dateTimeService: DateTimeService,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly shipperDataService: ShipperDataService,
    private readonly currencyFormatService: CurrencyFormatService
  ) {}

  ngOnInit() {
    this.logoutSubscription = this.logoutEvent.subscribe(() => this.onLogout());
    this.statusCtrl.valueChanges.subscribe(() => this.loadShipments());
    this.statusCtrl.setValue('inactive');
  }

  ngOnDestroy() {
    this.logoutSubscription.unsubscribe();
  }

  onLogout(): void {
    this.shipperDataService.logout();
  }

  loadShipments(page = 1, perPage = 10): void {
    this.loading = true;
    this.shipmentDataService
      .getShipmentsByStatus(page, perPage, [this.statusCtrl.value])
      .pipe(first())
      .subscribe(
        (shipments: Shipment[]) => {
          this.loading = false;
          this.shipmentList = (shipments as ShipmentModel[]) || [];
          this.length = this.shipmentList.length;
          this.totalLength = this.shipmentList.length;
          this.idCntrl.reset();
          this.filteredShipments = this.shipmentList;
          this.length = this.totalLength;
          this.addPropertiesToShipment(this.filteredShipments);
        },
        (error: string) => {
          this.errorMessage = ERROR_MESSAGE;
          this.loading = false;
        }
      );
  }

  clearFilters() {
    this.idInput.nativeElement.value = '';
  }

  onSearchInput() {
    this.filterShipment();
  }

  handlePage(pageEvent: PageEvent) {
    this.loadShipments(pageEvent.pageIndex + 1, pageEvent.pageSize);
  }

  filterShipment() {
    let searchText = this.idCntrl.value;
    if (searchText) {
      this.filteredShipments = this.shipmentList.filter(shipment => {
        if (!shipment) {
          return;
        }
        if (
          this.searchShipmentIDs(shipment.shipmentID, searchText) ||
          this.searchOrigins(shipment.origin, searchText) ||
          this.searchDestinations(shipment.destination, searchText) ||
          this.searchEstimatedPickup(shipment.formattedPickupDateAndTime, searchText) ||
          this.searchMode(shipment.fitsIn, searchText)
        ) {
          return shipment;
        }
      });
    }
    this.length = this.filteredShipments.length;
  }

  private searchShipmentIDs(shipmentID: string, searchText: any): boolean {
    if (shipmentID && searchText) {
      return shipmentID.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchOrigins(origin: string, searchText: any): boolean {
    if (origin && searchText) {
      return origin.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchDestinations(destination: string, searchText: any): boolean {
    if (destination && searchText) {
      return destination.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchEstimatedPickup(pickupDate: string, searchText: any): boolean {
    if (pickupDate && searchText) {
      return pickupDate.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private searchMode(mode: string, searchText: any): boolean {
    if (mode && searchText) {
      return mode.toLowerCase().includes(searchText.toLocaleLowerCase());
    }
  }

  private addPropertiesToShipment(shipments: ShipmentModel[]): void {
    if (!shipments) {
      return;
    }
    shipments.forEach((shipment: ShipmentModel) => {
      if (!shipment) {
        return;
      }
      this.assignFormattedCurrency(shipment);
      shipment.formattedPickupDateAndTime = this.dateTimeService.getConvertedDateAndTime(
        shipment.pickup.pickupDateFrom,
        shipment.pickup.pickupDateTo
      );
      shipment.formattedDeliveryDateAndTime = this.dateTimeService.getConvertedDateAndTime(
        shipment.delivery.deliveryDateFrom,
        shipment.delivery.deliveryDateTo
      );
    });
  }

  private assignFormattedCurrency(shipment: ShipmentModel): void {
    shipment.formattedOfferRate = this.currencyFormatService.convertToCurrency(shipment.offerRate);
  }
}
