import { Injectable } from '@angular/core';
import { Driver, DriverDataService } from '@fleetoperate/shared/driver/data-access-driver';
import { DriverProfileModule } from './driver-profile.module';
import { Observable } from 'rxjs/internal/Observable';
import { ToastService } from '@fleetoperate/shared/util';
import { DocumentsDataService, DriverDocument } from '@fleetoperate/shared/documents/data-access-documents';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: DriverProfileModule
})
export class DriverProfileService {
  constructor(
    private readonly driverDataService: DriverDataService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly toastService: ToastService
  ) {}

  getDriverById(id: string) {
    return this.driverDataService.loadDriver(id);

    // const driver = this.getMockDrivers().find(driver => driver.id === `${id}`);
    // return driver;
  }

  downloadDriverDocument(driverId: string, documentId: string): Observable<any> {
    return this.documentsDataService.getDriverDocument(driverId, documentId).pipe(
      map((driverDocument: DriverDocument) => {
        window.open(driverDocument.fileUrl);
        this.toastService.showSuccess('Successfully downloaded document');
      })
    );
  }

  private getMockDrivers(): Driver[] {
    return [
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: 'EFGH',
            fromDate: '20190101',
            toDate: '20191202',
            position: 'Driver'
          }
        ],
        experience: {},
        notification: [],
        preference: {},
        id: 'ef90e70a-3c74-4179-b944-18156a8b66b0',
        identityStatus: 'Pending',
        email: 'shreeharikoushik@gmail.com',
        firstName: 'Shreehari',
        lastName: 'N',
        phoneNumber: '9972359152'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: 'd0020648-97e6-42d2-9c61-dd3318d2eb4c',
        identityStatus: 'Pending',
        email: 'michael.capner+driver1@gmail.com',
        firstName: 'Michael',
        lastName: 'Manitoba',
        phoneNumber: '12046121185'
      },
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: '123',
            fromDate: '20180101',
            toDate: '20180228',
            position: 'Driver'
          },
          {
            id: '4124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: '456',
            fromDate: '20180229',
            toDate: '20190228',
            position: 'Driver'
          }
        ],
        experience: {
          id: '2630a7c1-b2b9-4fc1-a175-355cd1978d99',
          drivingGraduate: true,
          companyDriver: true,
          studentDriver: false,
          ownerOperator: false,
          yearsOfExperience: 2,
          truckTypes: [
            {
              type: 'others',
              flat: false,
              van: false,
              tank: true,
              displayType: 'Others'
            },
            {
              type: 'straightTruck',
              flat: true,
              van: true,
              tank: true,
              milesDriven: 2000,
              fromDate: '2018-12-31T18:30:00.000Z',
              toDate: '2019-12-01T18:30:00.000Z',
              displayType: 'Straight Truck'
            },
            {
              type: 'tractorAndSemiTrailer',
              flat: true,
              van: false,
              tank: false,
              displayType: 'Tractor and Semi-Trailer'
            },
            {
              type: 'tractorTwoTrailers',
              flat: false,
              van: true,
              tank: false,
              displayType: 'Tractor Two Trailers'
            }
          ]
        },
        preference: {
          id: '2630a7c1-b2b9-4fc1-a175-355cd1978d99',
          truckType: {
            any: false,
            hhg: true,
            reefer: true,
            hazmat: true,
            tanker: true,
            carHauling: true,
            flatBed: true,
            van: true,
            specialized: true,
            lcv: true
          },
          day: {
            weekdays: false,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            weekend: false,
            saturday: true,
            sunday: true
          }
        },
        notification: [
          {
            id: '3e999803-080c-4cff-bc1b-4ba2dec5a36a',
            type: 'KYC',
            status: 'verified',
            verifiedDate: '2019099'
          }
        ],
        education: {
          id: 'fea521e1-2c8c-4ab8-a82b-d92438dac942',
          highestGradeCompleted: '14',
          lastSchoolAttended: 'ABCD',
          certification: 'EFGH'
        },
        id: 'e0d7abd2-489b-49b6-b7f5-3440a51fde1d',
        identityStatus: 'Pending',
        email: 'michael.capner+driver2@gmail.com',
        firstName: 'Michael',
        lastName: 'Capner',
        phoneNumber: '12046121185'
      },
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: '123',
            fromDate: '20180101',
            toDate: '20180228',
            position: 'Driver'
          }
        ],
        experience: {
          id: '2630a7c1-b2b9-4fc1-a175-355cd1978d99',
          drivingGraduate: true,
          companyDriver: true,
          studentDriver: false,
          ownerOperator: false,
          yearsOfExperience: 2,
          truckTypes: [
            {
              type: 'others',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Others'
            },
            {
              type: 'straightTruck',
              flat: true,
              van: true,
              tank: true,
              milesDriven: 2000,
              fromDate: '2018-12-31T18:30:00.000Z',
              toDate: '2019-12-01T18:30:00.000Z',
              displayType: 'Straight Truck'
            },
            {
              type: 'tractorAndSemiTrailer',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor and Semi-Trailer'
            },
            {
              type: 'tractorTwoTrailers',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor Two Trailers'
            }
          ]
        },
        notification: [],
        preference: {
          id: '2630a7c1-b2b9-4fc1-a175-355cd1978d99',
          truckType: {
            any: false,
            hhg: true,
            reefer: true,
            hazmat: true,
            tanker: true,
            carHauling: true,
            flatBed: true,
            van: true,
            specialized: true,
            lcv: true
          },
          day: {
            weekdays: false,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            weekend: false,
            saturday: true,
            sunday: true
          }
        },
        education: {
          id: 'fea521e1-2c8c-4ab8-a82b-d92438dac942',
          highestGradeCompleted: '14',
          lastSchoolAttended: 'ABCD',
          certification: 'EFGH'
        },
        id: '2630a7c1-b2b9-4fc1-a175-355cd1978d99',
        identityStatus: 'Pending',
        email: 'begoor.chirantan72@gmail.com',
        firstName: 'Chirantan',
        lastName: 'K',
        phoneNumber: '9686170664'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: '9d90cf01-d3bb-4a8e-b1ca-448de2e12692',
        identityStatus: 'Pending',
        email: 'darren.capner+test1@gmail.com',
        firstName: 'Darren',
        lastName: 'Capner',
        phoneNumber: '2045994883'
      },
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: "Bob's Trucking",
            fromDate: '20171204',
            toDate: '20190418',
            position: 'Driver'
          },
          {
            id: '6dd91fc8-b4cd-42ec-ba39-a0768ce86ff7',
            employer: "Bob's Trucking",
            fromDate: '20171203',
            toDate: '20190417',
            position: 'Driver'
          }
        ],
        experience: {
          id: 'a2a50574-a71c-4f3f-9b5d-31a42532b1f7',
          drivingGraduate: false,
          companyDriver: true,
          studentDriver: false,
          ownerOperator: false,
          yearsOfExperience: 4,
          truckTypes: [
            {
              type: 'others',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Others'
            },
            {
              type: 'straightTruck',
              flat: false,
              van: false,
              tank: true,
              milesDriven: 3263,
              fromDate: '2018-01-10T06:00:00.000Z',
              toDate: '2018-02-13T06:00:00.000Z',
              displayType: 'Straight Truck'
            },
            {
              type: 'tractorAndSemiTrailer',
              flat: true,
              van: false,
              tank: false,
              milesDriven: 6335,
              fromDate: '2018-02-12T06:00:00.000Z',
              toDate: '2019-04-13T05:00:00.000Z',
              displayType: 'Tractor and Semi-Trailer'
            },
            {
              type: 'tractorTwoTrailers',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor Two Trailers'
            }
          ]
        },
        notification: [
          {
            id: '3e999803-080c-4cff-bc1b-4ba2dec5a36a',
            type: 'KYC',
            status: 'verified',
            verifiedDate: '2019099'
          }
        ],
        preference: {
          id: 'a2a50574-a71c-4f3f-9b5d-31a42532b1f7',
          truckType: {
            any: false,
            hhg: true,
            reefer: false,
            hazmat: true,
            tanker: true,
            carHauling: true,
            flatBed: true,
            van: true,
            specialized: true,
            lcv: true
          },
          day: {
            weekdays: true,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            weekend: true,
            saturday: true,
            sunday: true
          }
        },
        education: {
          id: '7952dd32-13d8-4d83-8d2f-2cec64125ece',
          highestGradeCompleted: '12',
          lastSchoolAttended: 'Fury Road High',
          certification: 'Road Warrior'
        },
        id: 'a2a50574-a71c-4f3f-9b5d-31a42532b1f7',
        identityStatus: 'Pending',
        email: 'darren.capner@gmail.com',
        firstName: 'Darren',
        lastName: 'Capner',
        phoneNumber: '2045994883'
      },
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            employer: 'Jackfruit',
            fromDate: '20190804',
            toDate: '20191124',
            position: 'Tester'
          }
        ],
        experience: {
          id: '05d7ff8e-22e8-4ebb-8338-02683bf9627c',
          drivingGraduate: true,
          companyDriver: false,
          studentDriver: true,
          ownerOperator: false,
          yearsOfExperience: 5,
          truckTypes: [
            {
              type: 'others',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Others'
            },
            {
              type: 'straightTruck',
              flat: false,
              van: false,
              tank: true,
              milesDriven: 500000,
              fromDate: '2019-08-04T05:00:00.000Z',
              toDate: '2019-11-24T06:00:00.000Z',
              displayType: 'Straight Truck'
            },
            {
              type: 'tractorAndSemiTrailer',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor and Semi-Trailer'
            },
            {
              type: 'tractorTwoTrailers',
              flat: false,
              van: true,
              tank: false,
              milesDriven: 1,
              fromDate: '2019-11-12T06:00:00.000Z',
              toDate: '2019-11-24T06:00:00.000Z',
              displayType: 'Tractor Two Trailers'
            }
          ]
        },
        notification: [
          {
            id: '0b27040d-55ab-446a-983c-de40ba49b2cc',
            type: 'KYC',
            status: 'notverified',
            verifiedDate: '20191125'
          }
        ],
        preference: {
          id: '05d7ff8e-22e8-4ebb-8338-02683bf9627c',
          truckType: {
            any: false,
            hhg: false,
            reefer: false,
            hazmat: false,
            tanker: true,
            carHauling: false,
            flatBed: false,
            van: false,
            specialized: false,
            lcv: false
          },
          day: {
            weekdays: false,
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            weekend: false,
            saturday: true,
            sunday: true
          }
        },
        education: {
          id: 'c35e81b3-df59-482a-9685-48d2421afa05',
          highestGradeCompleted: '12',
          lastSchoolAttended: 'GVSD',
          certification: 'Grade 12'
        },
        id: '05d7ff8e-22e8-4ebb-8338-02683bf9627c',
        identityStatus: 'Pending',
        email: 'courtneywall07+D1@gmail.com',
        firstName: 'C',
        lastName: 'Capner',
        phoneNumber: '12044706545'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: '2fb9ed06-e33a-4f71-b627-eaf8946cf740',
        identityStatus: 'Pending',
        email: 'darren.capner+driver1@gmail.com',
        firstName: 'Darren',
        lastName: 'Capner',
        phoneNumber: '2045994883'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: 'ded2fc9f-6646-4ee6-ace1-99510fe8848c',
        identityStatus: 'Pending',
        email: 'knagaraj@jackfruitsystems.com',
        firstName: 'Krishna',
        lastName: 'Nagaraj',
        phoneNumber: '9035018770'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: '62ff5852-5469-4db2-966e-863a474e38f1',
        identityStatus: 'Pending',
        email: 'michael.capner+test4@gmail.com',
        firstName: 'Michael',
        lastName: 'Capner',
        phoneNumber: '12046121185'
      },
      {
        employment: [
          {
            id: '5124ca91-f340-4b37-bfd2-395f3e6c045c',
            noExperience: true
          }
        ],
        experience: {
          id: 'c5ed4880-e8f1-4ba9-b432-586a4e4b3173',
          drivingGraduate: false,
          companyDriver: false,
          studentDriver: false,
          ownerOperator: false,
          yearsOfExperience: 2,
          truckTypes: [
            {
              type: 'others',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Others'
            },
            {
              type: 'straightTruck',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Straight Truck'
            },
            {
              type: 'tractorAndSemiTrailer',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor and Semi-Trailer'
            },
            {
              type: 'tractorTwoTrailers',
              flat: false,
              van: false,
              tank: false,
              displayType: 'Tractor Two Trailers'
            }
          ]
        },
        notification: [
          {
            id: 'd480378e-2f8f-4cb8-8a45-2429f023541a',
            type: 'KYC',
            status: 'Completed'
          }
        ],
        preference: {
          id: 'c5ed4880-e8f1-4ba9-b432-586a4e4b3173',
          truckType: {
            any: false,
            hhg: true,
            reefer: true,
            hazmat: true,
            tanker: true,
            carHauling: true,
            flatBed: true,
            van: true,
            specialized: true,
            lcv: true
          },
          day: {
            weekdays: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            weekend: false,
            saturday: false,
            sunday: false
          }
        },
        education: {
          id: '9c646b08-f640-4cef-8671-5f57ebdb3f2d',
          highestGradeCompleted: '1',
          lastSchoolAttended: '2',
          certification: '3'
        },
        id: 'c5ed4880-e8f1-4ba9-b432-586a4e4b3173',
        identityStatus: 'Pending',
        email: 'michael.capner+test10@gmail.com',
        firstName: 'Michael',
        lastName: 'Camper',
        phoneNumber: '2046121185'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: '701ad0eb-682e-4a51-82cf-7d0cf16c7f48',
        identityStatus: 'Pending',
        email: 'darren.capner+driver2@gmail.com',
        firstName: 'Darren1',
        lastName: 'Capner1',
        phoneNumber: '12045994883'
      },
      {
        employment: [],
        experience: {},
        notification: [],
        preference: {},
        id: '9addbc7b-8e5e-4e9c-90b5-82f1d777f0f4',
        identityStatus: 'Pending',
        email: 'dcstingray+driver1@hotmail.com',
        firstName: 'Darren',
        lastName: 'Capner',
        phoneNumber: '12045994883'
      }
    ] as Driver[];
  }
}
