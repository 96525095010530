import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { Router } from '@angular/router';
import { LoggerService } from '@fleetoperate/shared/util';
import { RecruiterDataService } from '@fleetoperate/shared/delivery-recruiter/data-access-recruiter';
import { ROUTE_SIGNIN } from '@fleetoperate/shared/authentication/feature-authentication';
import { ROUTE_DRIVER_SEARCH } from '@fleetoperate/delivery-recruiter/feature-driver';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly recruiterDataService: RecruiterDataService,
    private readonly logger: LoggerService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.recruiterDataService.loadRecruiter().subscribe(
        () => {
          this.navigateToRecruiterHome();
        },
        (error: any) => {
          this.logger.error(`Unable to load carrier. Sending user to sign in.`);
          // this.navigateToSignIn();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private navigateToRecruiterHome(): void {
    this.router.navigate([ROUTE_DRIVER_SEARCH]);
  }

  private navigateToSignIn(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }
}
