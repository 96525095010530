import { Injectable, Inject } from '@angular/core';
import { FleetoperateApiService, Response } from '@fleetoperate/shared/data-access';
import { AuthService } from '@fleetoperate/shared/authentication/data-access';
import { DriverDataModule } from './driver-data.module';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

const PATH_DRIVERS = '/drivers';
const PATH_GET_DRIVER = PATH_DRIVERS + '/{id}';

@Injectable({
  providedIn: DriverDataModule
})
export class DriverApiService {

  constructor(private readonly api: FleetoperateApiService,
              private readonly authService: AuthService,
              @Inject('apiUrl') private apiUrl: string) { }

  getDriver(id: string): Observable<Response> {
    const path = PATH_GET_DRIVER.replace('{id}', id);
    return this.authService.isAuthenticated().pipe(
      mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token))
    );
  }

  getDrivers(): Observable<Response> {
    const path = PATH_DRIVERS;
    return this.authService.isAuthenticated().pipe(
      mergeMap(() => this.api.get(`${this.apiUrl}${path}`, {}, this.authService.token))
    );
  }
}
