import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiKitMaterialModule } from '@fleetoperate/ui-kit-material';
import { SharedAuthenticationFeatureAuthenticationModule } from '@fleetoperate/shared/authentication/feature-authentication';
import { RecruiterFeatureShellRoutingModule } from './recruiter-feature-shell-routing.module';
import { ShellModule } from './shell/shell.module';
import { RegistrationService } from '@fleetoperate/shared/registration/feature-registration';
import { RecruiterFeatureDriverModule } from '@fleetoperate/delivery-recruiter/feature-driver';
import { RecruiterFeatureShipperModule } from '@fleetoperate/delivery-recruiter/feature-shipper';

@NgModule({
  imports: [
    CommonModule,
    UiKitMaterialModule,
    ShellModule,
    SharedAuthenticationFeatureAuthenticationModule,
    RecruiterFeatureDriverModule,
    RecruiterFeatureShipperModule,
    RecruiterFeatureShellRoutingModule
  ],
  providers: [
    RegistrationService // TODO: Decouple this from @fleetoperate/shared/authentication/feature-authentication
  ]
})
export class RecruiterFeatureShellModule {}
