import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Shipper, ShipperDataService } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { ShipperSearchModule } from './shipper-search.module';

@Injectable({
  providedIn: ShipperSearchModule
})
export class ShipperSearchService {
  constructor(private readonly shipperDataService: ShipperDataService) {}

  findShippers(): Observable<Shipper[]> {
    return this.shipperDataService.loadShippers();
  }
}
