import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'fleetoperate-driving-experience',
  templateUrl: './driving-experience.component.html',
  styleUrls: ['./driving-experience.component.scss']
})
export class DrivingExperienceComponent implements OnInit {

  @Input() group: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
