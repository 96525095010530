export class TruckType {
    flat: boolean;
    tank: boolean;
    van: boolean;
    milesDriven: number;
    other: string;
    fromDate: any;
    toDate: any;
    type: string;
    displayType: string;
}
