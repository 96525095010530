<fleetoperate-fleetoperate-toolbar [data]="user" (logout)="onLogout()"></fleetoperate-fleetoperate-toolbar>
<fleetoperate-header [title]="title" class="margin-left-md margin-right-md"></fleetoperate-header>

<div fxLayout class="options">
  <form class="shipper-search-filters margin-right-sm margin-left-sm" fxFlex>
    <mat-form-field fxFlexAlign="center">
      <input matInput placeholder="Search by ID" [formControl]="idCntrl" (input)="onSearchInput()" #idInput />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </form>
  <div fxLayout fxLayout.lt-md="column">
    <button
      class="button secondary-button"
      (click)="onNavigateToProdSupport()"
      fx-layout-align="end"
      mat-stroked-button
    >
      Prod Support
    </button>
    <button class="button primary-button" (click)="onNavigateToDriverSearch()" fx-layout-align="end" mat-stroked-button>
      Drivers
    </button>
  </div>
</div>
<div fxLayout="column" class="margin-bottom-md margin-right-sm margin-bottom-md margin-left-sm">
  <span *ngIf="errorMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ errorMessage }}</span>
  <table mat-table [dataSource]="filteredShippers" class="full-width mat-elevation-z8">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Shipper Id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Company Column -->
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Shipper Company</th>
      <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
    </ng-container>

    <!-- Address Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element">{{ element.address }}</td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="profileStatus">
      <th mat-header-cell *matHeaderCellDef>Profile Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div fxFlexAlign="center" class="table-message">
    <mat-spinner class="margin-top-md" *ngIf="loading"></mat-spinner>
    <p *ngIf="(!filteredShippers || filteredShippers.length <= 0) && !loading">No shippers found.</p>
  </div>
</div>
