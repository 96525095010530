import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { User } from '@fleetoperate/shared/ui/fleetoperate-toolbar';
import { TabActionLink } from '@fleetoperate/shared/ui/header';
import { ROUTE_DRIVER_SEARCH } from '../../shared/routes';
import { Router } from '@angular/router';

@Component({
  selector: 'fleetoperate-driver-prod-support',
  templateUrl: './driver-prod-support.component.html',
  styleUrls: ['./driver-prod-support.component.scss']
})
export class DriverProdSupportComponent implements OnInit {
  title: string = 'Driver Prod Support';
  user: User;
  actions: Array<TabActionLink> = [];
  backAction: Function;
  logoutSubject: Subject<void> = new Subject<void>();
  viewCtrl = new FormControl();
  views: Object[] = [{ value: 'appStatus', text: 'App Status' }, { value: 'errors', text: 'System Errors' }];
  showDriverStatus: boolean;
  showErrors: boolean;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    this.viewCtrl.valueChanges.subscribe(val => {
      this.showDriverStatus = val === 'appStatus';
      this.showErrors = val !== 'appStatus';
    });
    this.viewCtrl.setValue('errors');
    this.backAction = () => {
      this.router.navigate([ROUTE_DRIVER_SEARCH]);
    };
  }

  onLogout(): void {
    this.logoutSubject.next();
  }
}
