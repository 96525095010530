import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyFormatModule, DateTimeModule } from '@fleetoperate/shared/util';
import { ShipperDataModule } from '@fleetoperate/shared/delivery-shipper/data-access-shipper';
import { MatRadioModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [ShipmentListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FlexLayoutModule,
    CurrencyFormatModule,
    DateTimeModule,
    ShipperDataModule
  ],
  exports: [ShipmentListComponent]
})
export class ShipmentListModule {}
